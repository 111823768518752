import {
  useMediaQuery as useReactResponsiveMQ,
} from 'react-responsive';

export default function useMediaQuery() {
  const isTablet = useReactResponsiveMQ({ minWidth: 769 });
  const isDesktop = useReactResponsiveMQ({ minWidth: 1025 });
  const isTouchDevice = (('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));

  return {
    isTablet,
    isDesktop,
    isTouchDevice,
  };
}
