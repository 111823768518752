import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';

import styles from './BackgroundImage.module.scss';

const BackgroundImage = ({ image, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imageObj = useRef({});

  useEffect(() => {
    imageObj.current = new Image();
    imageObj.current.onload = () => setIsLoaded(true);
    imageObj.current.src = image.sizes.xl;
  }, [image]);

  return ReactDOM.createPortal((
    <div {...props} className={styles.Container}>
      <span className={styles.LowRes} style={{
        backgroundImage: `url(${image.sizes.medium})`,
      }} />
      <Transition
        in={isLoaded}
        onEnter={(node) => {
          gsap.set(node, { autoAlpha: 0 });
        }}
        addEndListener={(node, done) => {
          gsap.to(node, {
            autoAlpha: 1,
            duration: 1,
            ease: 'Power3.easeOut',
            onComplete: done 
          });
        }}
      >
        <img src={imageObj.current.src} alt={image.alt} />
      </Transition>
    </div>
  ), document.getElementById('CanvasPortal'));
};

export default BackgroundImage;
