import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import Scrollbar from 'smooth-scrollbar';

import { Modal, Button } from '../../components';
import styles from './AboutBio.module.scss';

const AboutBio = ({
  profile,
  flip = false,
}) => {
  const [showModal, setShowModal] = useState(false);
  const {
    name,
    bio,
    photo: {
      alt,
      sizes: {
        large,
      },
    },
    mobile,
    email,
    license
  } = profile;
  const scrollRef = useRef(null);

  const splitBio = bio.split('<!-- more -->');

  useEffect(() => {
    let sb;
    if (scrollRef.current) {
      sb = Scrollbar.init(scrollRef.current, {});
      Scrollbar.detachStyle();
    }

    return () => {
      if (sb) sb.destroy();
    }
  }, [showModal]);

  return (
    <div className={clsx('color-white', styles.Container, {
      flip,
      expanded: splitBio.length === 0,
    })}>
      <div className={styles.Photo}>
        <div className={styles.PhotoInner}>
          <img src={large} alt={alt} />
        </div>
      </div>
      <div className={styles.Content}>
        <div className={styles.Bio}>
          <h2>{name}</h2>
          {parse(splitBio[0])}
          {splitBio.length > 1 && (
            <Button variant="text" onClick={() => setShowModal(true)}>
              Learn More <span className="material-icons">add</span>
            </Button>
          )}
        </div>
        <div className={styles.Info}>
          <div>
            <strong>Contact</strong>
            {mobile && (
              <p>
                <span>Mobile</span>
                <span>{mobile}</span>
              </p>
            )}
            {email && (
              <p>
                <span>Email</span>
                <span>{email}</span>
              </p>

            )}
          </div>
          {license && (
            <div>
              <strong>License</strong>
              <p>
                <span>DRE</span>
                <span>{license}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      
      {splitBio.length > 1 && (
        <Modal
          lightbox
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <div className={styles.ScrollContent}>
            <div className={`${styles.ExpandedBio} color-white`}>
              <div>
                <img src="http://nealward-wp-offload.s3.us-west-1.amazonaws.com/content/uploads/2019/05/30100136/Screen-Shot-2019-05-30-at-10.00.17-AM.png" alt="Temp" />
                <div className={styles.Contact}>
                  <strong>Contact</strong>
                  {mobile && (
                    <p>
                      <span>Mobile</span>
                      <span>{mobile}</span>
                    </p>
                  )}
                  {email && (
                    <p>
                      <span>Email</span>
                      <span>{email}</span>
                    </p>
                  )}
                  {license && (
                    <p>
                      <span>DRE</span>
                      <span>{license}</span>
                    </p>
                  )}
                </div>
              </div>
              <div className={`${styles.BioContainer} display-flex flex-column`}>
                <h3>{name}</h3>
                <div className={styles.ScrollView} ref={scrollRef}>
                  {parse(bio)}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AboutBio;
