import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: 'rgb(0, 154, 222)',
    }
  },
  colors: {
    white: '#FEFEFE',
    blue: 'rgb(0, 154, 222)',
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      'futura-pt',
      'sans-serif',
    ].join(','),
  }
});

export default theme;
