export const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'sold', label: 'Sold' },
];

export const priceOptions = [
  { value: '0-5M', label: 'Up to $5M' },
  { value: '5M-8M', label: '$5M-8M' },
  { value: '8M-10M', label: '$8M-10M' },
  { value: '10M-12M', label: '$10M-12M' },
  { value: '12M-20M', label: '$12M-20M' },
  { value: '20M+', label: '$20M+' },
];

export const sortOptions = [
  { value: 'date:desc', label: 'Newest Listings' },
  { value: 'price:desc', label: 'Highest Price' },
  { value: 'price:asc', label: 'Lowest Price' },
];
