// import debounce from 'lodash/debounce';

export default class NativeScroll {
  _listeners = new Set();
  _boundScrollHandler = this.handleScroll.bind(this);

  constructor() {
    window.addEventListener('scroll', this._boundScrollHandler);
  }

  get scrollTop() {
    return window.scrollY;
  }

  handleScroll(e) {
    this._listeners.forEach((fn) => {
      fn.call(this, {
        offset: {
          x: window.scrollX,
          y: window.scrollY,
        },
      });
    });
  }

  addListener(fn) {
    if (typeof fn !== 'function') {
      throw new TypeError('Scroll listener should be a function');
    }

    this._listeners.add(fn);
  }

  removeListener(fn) {
    this._listeners.delete(fn);
  }

  scrollTo(x, y, duration, options) {
    window.scroll({
      top: y,
      left: x,
      behavior: duration !== 0 ? 'smooth' : 'auto',
    });
  }

  scrollIntoView(elem, options) {
    elem.scrollIntoView({ behavior: 'smooth' });
  }

  update() {
    // console.log('update');
  }

  destroy() {
    window.removeEventListener('scroll', this._boundScrollHandler);
    this._listeners.clear();
  }
}
