import { useQuery } from 'react-query';
import axios, { CancelToken } from 'axios';

const getSearch = ({ queryKey }) => {
  const [, term] = queryKey;
  if (term === '') {
    return Promise.resolve(null);
  }

  const source = CancelToken.source();
  const promise = axios.get(
    `${process.env.REACT_APP_API_URL}/property-search?s=${term}`, {
      cancelToken: source.token,
    }
  );
  promise.cancel = () => {
    source.cancel('Search query was canceled');
  }
  return promise.then(({ data }) => data);
};

export default function useSearch(term) {
  return useQuery(['search', term], getSearch, {
    enabled: false,
    keepPreviousData: true,
  });
};