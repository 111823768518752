import React, { useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from 'smooth-scrollbar';
import { Button, Modal } from '../../components';
import useMediaQuery from '../../hooks/useMediaQuery';
import useProperty from '../../hooks/data/useProperty';
import { useLoco } from '../../state/LocoContext';

import styles from './PropertyDetails.module.scss';

const sectionNameMap = {
  'detail_sheet_section': 'Property Abstract',
  'floor_plans': 'Floor Plans',
  'photo_gallery_section': 'Image Gallery',
  'video_section': 'Property Video',
};

function sectionNameFromLayout(layout) {
  return sectionNameMap[layout];
}

const PropertyDetails = (props) => {
  const { slug = 'nw' } = useParams();
  const descriptRef = useRef();
  const moreTextRef = useRef();
  const { isDesktop } = useMediaQuery();
  const { data: propertyData = {} } = useProperty(slug);
  const [expanded, setExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { scrollTo, scrollIntoView } = useLoco();

  const {
    acf: {
      listing_details: {
        neighborhood,
        title,
      } = {},
      listing_content: {
        description,
        features,
        links = [],
      } = {},
      listing_status: {
        confidential,
        price,
        sale_price: salePrice,
        status: listingStatus,
      } = {},
      sections,
    } = {},
  } = propertyData;

  const scrollIntoViewByID = (id) => {
    const elem = document.getElementById(id);
    scrollIntoView(elem);
  };
  
  useEffect(() => {
    if (descriptRef.current) {
      setShowReadMore(descriptRef.current.scrollHeight > descriptRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    let sb;
    if (moreTextRef.current) {
      sb = Scrollbar.init(moreTextRef.current, {});
      Scrollbar.detachStyle();
    }

    return () => {
      if (sb) sb.destroy();
    }
  }, [showModal]);

  return (
    <div {...props} className={styles.Details}>
      <div className={styles.DetailsContent}>
        <header className={styles.DetailHeader}>
          {neighborhood && <p className="eyebrow color-white">{neighborhood}</p>}
          <h2>{title}</h2>
        </header>
        <div className={styles.DetailBody}>
          <div className={clsx(styles.PropertyDescription, {
            'show-more': expanded,
          })}>
            <div className={styles.Expandable} ref={descriptRef}>
              {parse(description)}
            </div>
            {showReadMore && (
              <Button
                onClick={() => setShowModal(true)}
                variant="text"
              >Read More <span className="material-icons">add</span></Button>
            )}
          </div>
          <ul className={styles.DetailSpecs}>
            <li>
              <span>{listingStatus === 'active' ? 'Offered at' : 'Sold for'}</span>
              <span>{listingStatus === 'active' ? price : confidential ? 'Confidential' : salePrice}</span>
            </li>
            {features.map(({ feature, label = '', order }) => {
              const fLabel = label !== '' ? label : 'Feature';
              return(
                <li key={`feature_${order}`}>
                  <span>{fLabel}</span><span dangerouslySetInnerHTML={{__html: feature}} />
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.DetailActions}>
          {isDesktop && sections && (
            sections.map(({ acf_fc_layout: layout, order }) => (
              <Button key={`${layout}__${order}`} onClick={() => {
                scrollIntoViewByID(`${layout}__${order}`);
              }}>
                {sectionNameFromLayout(layout)}
              </Button>
            ))
          )}
          {isDesktop && links.length && (
            links.map(({ link: { title, url, target }}) => (
              <Button key={title} onClick={() => {
                window.open(url, target);
              }}>{title}</Button>
            ))
          )}
          {!isDesktop && expanded && (
            <Button
              onClick={() => {
                scrollTo(0, window.innerHeight, 0);
                setExpanded(false)
              }}
              variant="icon-circle"
            >
              <span className="material-icons">horizontal_rule</span>
            </Button>
          )}
        </div>
      </div>
      {showReadMore && (
        <Modal open={showModal} lightbox onClose={() => setShowModal(false)}>
          <div className={`${styles.ReadMoreModal} color-white`}>
            <div className={styles.ReadMoreText} ref={moreTextRef}>
              {parse(description)}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PropertyDetails;
