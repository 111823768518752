import React, { useState } from 'react';

import styles from './Slider.module.scss';

const Slide = React.forwardRef(({ property, disabled, ...props }, ref) => {
  const [pressed, setPressed] = useState(false);

  const {
    acf: {
      listing_details: {
        neighborhood,
        title,
      },
    },
    featured_image: {
      alt,
      sizes,
    }
  } = property;

  return (
    <div
      {...props}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      ref={ref}
      className={styles.Slide}
      disabled={disabled}
      tabIndex={0}
      role="button"
      aria-label={title}
      aria-pressed={pressed}
    >
      <span className={styles.ImageContainer}>
        <img src={sizes.medium} alt={alt} />
      </span>
      <div className={styles.Title}>
        {neighborhood && <p className="eyebrow color-black">{neighborhood}</p>}
        <p className="title color-black">{title}</p>
      </div>
    </div>
  );
});

export default Slide;
