import React from 'react';
import { Button } from '../../components';
import { ContactListItem } from '../../partials';

import styles from './CoListFooter.module.scss';

const CoListFooter = ({ data }) => {
  const {
    acf: {
      co_listed_details: {
        agents = [],
        showing_contact,
        showing_contact: {
          show_contact,
          agents_image,
        } = {},
      } = {},
      listing_content: {
        links = [],
      } = {},
    } = {},
  } = data;

  console.log(showing_contact);

  return (
    <section className={`${styles.Container} color-white stitch`}>
      <header>
        <h5 className="no-margin">Information</h5>
      </header>
      <div className={styles.Content}>
        {show_contact === true ? (
          <div>
            <p className="large-body no-margin">For showings, please contact:</p>
            <ContactListItem info={showing_contact} />
            {links.length && (
              <div className={styles.Links}>
                {links.map(({ link: {title, target, url }}) => (
                  <Button key={title} onClick={() => {
                    window.open(url, target);
                  }}>{title}</Button>
                ))}
              </div>
            )}
          </div>
        ) : (
          agents_image ? (
            <div className={styles.AgentsImage}>
              <img src={agents_image.sizes.xl} alt={agents_image.alt} />
            </div>
          ) : (
            <div />
          )
        )}
        <div>
          <p className="large-body no-margin">This property is co-listed by {agents[0].agent.post_title} and Neal Ward.</p>
          <div className={styles.Agents}>
            {agents.map(({agent: { post_title: name, acf: { license, brokerage, email, phone }}}) => (
              <ContactListItem
                key={name.replace(/ /g, '')}
                info={{ email, phone, name, brokerage, license }}
              />
            ))}
            <ContactListItem info={{
              name: 'Neal Ward',
              brokerage: 'Compass',
              license: '01052285',
              phone: '415.269.9933',
              email: 'neal@compass.com',
            }} />
          </div>
          <p className={styles.Disclaimer}>Compass is a real estate broker licensed by the State of California and abides by Equal Housing Opportunity laws. License Number 01052285. All material presented herein is intended for informational purposes only and is compiled from sources deemed reliable but has not been verified. Changes in price, condition, sale or withdrawal may be made without notice. No statement is made as to accuracy of any description. All measurements and square footage are approximate.</p>
        </div>
      </div>
    </section>
  );
};

export default CoListFooter;
