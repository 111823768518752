import React from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';
import { CircularProgress } from '@material-ui/core';

const Button = ({ children, variant, className, isLoading, ...rest }) => {
  return (
    <button
      disabled={isLoading}
      {...rest}
      data-variant={variant}
      className={clsx(styles.Button, className)}
    >
      {children}
      {isLoading && (
        <div style={{
          position: 'absolute',
          display: 'flex',
        }}>
          <CircularProgress size={18} />
        </div>
      )}
    </button>
  );
};

export default Button;
