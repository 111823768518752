import { useQuery } from 'react-query';
import axios from 'axios';

const getSettings = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/settings`
  );
  return data;
};

export default function useSettingsData() {
  return useQuery(['settings'], getSettings);
};
