import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Player from '@vimeo/player';
import { Button } from '../';

import styles from './VideoPlayer.module.scss';

const VideoPlayer = ({ video, onClose = () => {} }) => {
  const videoEl = useRef(null);
  const player = useRef(null);

  // const onClickHandler = () => {
  //   player.current.getPaused()
  //     .then((paused) => {
  //       if (paused) {
  //         player.current.play();
  //       } else {
  //         player.current.pause();
  //       }
  //     });
  // };

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  useEffect(() => {
    const _player = new Player(videoEl.current, {
      url: video,
      byline: false,
      controls: true,
      portrait: false,
      title: false,
      autoplay: true,
    });

    player.current = _player;

    return () => {
      _player.destroy();
    }
  }, [video]);

  return ReactDOM.createPortal((
    <div className={styles.Container}>
      <div className={styles.Player} ref={videoEl} />
      <div className={styles.Controls}>
        <Button variant="icon" onClick={handleClose}>
          <span className="color-white material-icons">close</span>
        </Button>
      </div>
    </div>
  ), document.getElementById('ModalPortal'));
};

export default VideoPlayer;
