import React, { useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';

const Image = React.forwardRef(({src, alt, ...props}, ref) => {
  const imageObj = useRef();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    imageObj.current = new window.Image();
    imageObj.current.onload = () => setLoaded(true);
    imageObj.current.src = src;

    return () => {
      imageObj.current.onload = null;
      imageObj.current.src = null;
    }
  }, [src]);

  return (
    <Transition
      in={loaded}
      onEnter={(node) => {
        gsap.set(node, { autoAlpha: 0 });
      }}
      addEndListener={(node, done) => {
        gsap.to(node, {
          autoAlpha: 1,
          duration: 0.26,
          onComplete: done,
          ease: 'Power3.out',
        });
      }}
    >
      <>
        {loaded && (
          <img ref={ref} {...props} src={imageObj.current.src} alt={alt} />
        )}
      </>
    </Transition>
  );
});

export default Image;
