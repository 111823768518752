import React from 'react';

const LongArrowIcon = ({ stroke = 'white' }) => {
  return (
    <svg width="25" height="10" viewBox="0 0 25 10" fill="none">
      <path d="M0 5H25M25 5L20 0.5M25 5L20 9.5" stroke={stroke} />
    </svg>
  );
};

export default LongArrowIcon;
