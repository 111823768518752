import React, { useState, useCallback, useContext } from 'react';

const MenuContext = React.createContext({
  isMenuOpen: false,
  setIsMenuOpen: () => {},
  toggleMenu: () => {},
});

export function MenuContextProvider({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback((value) => {
    requestAnimationFrame(() => {
      setIsMenuOpen(value);
    });
  }, []);

  return (
    <MenuContext.Provider value={{
      isMenuOpen,
      setIsMenuOpen,
      toggleMenu,
    }}>
      {children}
    </MenuContext.Provider>
  )
}

export function useMenu() {
  return useContext(MenuContext);
}

export default MenuContext;
