import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import Player from '@vimeo/player';
import gsap from 'gsap';
import clsx from 'clsx';
import { Transition } from 'react-transition-group';
import styles from './BackgroundVideo.module.scss';

const BackgroundVideo = React.forwardRef(({
  video,
  start,
  end,
  paused = false,
  className = '',
  ...restProps
}, ref) => {
  const player = useRef(null);
  const holder = useRef(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    player.current = new Player(holder.current, {
      url: video,
      background: true,
      quality: '540p'
    });
    player.current.on('loaded', () => {
      player.current.setCurrentTime(start)
        .then(() => setLoaded(true));
    });
    player.current.on('timeupdate', (e) => {
      if (e.seconds >= end) {
        player.current.setCurrentTime(start);
      }
    })
    return () => {
      player.current.off('loaded');
      player.current.off('timeupdate');
      player.current.destroy();
    }
  }, [end, start, video]);

  useEffect(() => {
    if (player.current && loaded) {
      paused ? player.current.pause() : player.current.play();
    }
  }, [paused, loaded]);

  return ReactDOM.createPortal((
    <Transition
      in={loaded}
      onEnter={(node) => {
        gsap.set(node, {autoAlpha: 0});
      }}
      addEndListener={(node, done) => {
        gsap.to(node, {autoAlpha: 1, duration: 1, onComplete: done});
      }}
    >
      <div
        {...restProps}
        className={clsx(styles.Holder, className)}
        ref={holder}
      />
    </Transition>
  ), document.getElementById('CanvasPortal'));
});

export default BackgroundVideo;
