import React, { useState, lazy, Suspense } from 'react';

import styles from './MobileGallery.module.scss';

const PSGallery = lazy(() => import('./PSGallery'));

const MobileGallery = ({ images, ...props }) => {
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [activeIdx, setActiveIdx] = useState();

  const onSelectThumb = (idx) => {
    setActiveIdx(idx);
    setShowSlideshow(true);
  };

  return (
    <div {...props} className={styles.Container}>
      <p className="color-white">Tap on thumbnails to enlarge.</p>
      <div className={styles.ThumbnailsWrapper}>
        {images.map((thumb, idx) => (
          <div
            key={thumb.ID}
            onClick={() => onSelectThumb(idx)}
          >
            <img src={thumb.sizes.medium} alt={thumb.alt} />
          </div>
        ))}
      </div>
      {showSlideshow &&
        <Suspense fallback={<p>Loading...</p>}>
          <PSGallery
            images={images}
            activeIdx={activeIdx}
            onClose={() => setShowSlideshow(false)}
          />
        </Suspense>
      }
    </div>
  );
};

export default React.memo(MobileGallery);
