import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import ScrollTrigger from 'gsap/ScrollTrigger';
import LocoContext, { LocoContextProvider } from '../../state/LocoContext';

class LocoRouterBase extends Component {
  history = createHistory(this.props);

  constructor(props) {
    super(props);
    this.history.listen(this.updateLoco.bind(this));
  }

  updateLoco() {
    // const { scrollTo, update } = this.props;
    const st = ScrollTrigger.getAll();
    st.forEach(s => s.kill());

    // scrollTo(0, 0, 0);
    // requestAnimationFrame(() => {
    //   update();
    // });
  }
  
  render() {
    return (
      <Router history={this.history} children={this.props.children} />
    );
  }
}

const LocoRouter = (props) => (
  <LocoContextProvider>
    <LocoContext.Consumer>
      {({ update, scrollTo }) => (
        <LocoRouterBase {...props} scrollTo={scrollTo} update={update} />
      )}
    </LocoContext.Consumer>
  </LocoContextProvider>
);

export default LocoRouter;
