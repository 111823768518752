import React from 'react';
import clsx from 'clsx';

import styles from './TextBlock.module.scss';

const TextBlock = ({
  title,
  text,
  center = false,
  theme = 'light',
  withBg = false,
  largeTitle = false,
}) => {
  return (
    <section className={clsx(styles.Container, {
        'bg-white': theme === 'light',
        'nw-bg-light': withBg,
        'text-center': center,
        'large-title': largeTitle,
        stitch: theme === 'light',
      }, `theme-${theme}`)}>
      <h2 className="no-margin">{title}</h2>
      <p className="large-body">{text}</p>
    </section>
  );
};

export default TextBlock;
