import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import clsx from 'clsx';
import useSettingsData from '../../hooks/data/useSettingsData';

import styles from './SocialList.module.scss';

const SocialList = ({className, ...props}) => {
  const { data, status } = useSettingsData();

  return status === 'success' && (
    <ul {...props} className={clsx(styles.List, className)}>
      <li>
        <a
          href={data.acf.social.facebook}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        ><FontAwesomeIcon icon={faFacebookF} /></a>
      </li>
      <li>
        <a
          href={data.acf.social.twitter}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Twitter"
        ><FontAwesomeIcon icon={faTwitter} /></a>
      </li>
      <li>
        <a
          href={data.acf.social.instagram}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        ><FontAwesomeIcon icon={faInstagram} /></a>
      </li>
      <li>
        <a
          href={data.acf.social.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        ><FontAwesomeIcon icon={faLinkedin} /></a>
      </li>
    </ul>
  );
};

export default SocialList;