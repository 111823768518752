import React from 'react';
import ReactDOM from 'react-dom';
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackContext } from '../../state/SnackContext';

export default function SnackbarContainer() {
  const { snackOpen, onClose } = useSnackContext();

  return ReactDOM.createPortal((
    <Snackbar
      open={snackOpen}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      message="Thank you! Your message was sent successfully!"
      onClose={onClose}
      autoHideDuration={5000}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  ), document.querySelector('#SnackPortal'));
}
