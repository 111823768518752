import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useSiteContext } from '../../state/SiteContext';
import useProperty from '../../hooks/data/useProperty';
import { useTransition } from '../../state/TransitionContext';
import { ReactComponent as Logo } from '../../logo.svg';
import { ReactComponent as CompassLogo } from '../../compass-logo.svg';

import styles from './Footer.module.scss';

const hideAboutInRoutes = ['about', 'contact'];

const Footer = () => {
  const { pathname } = useLocation();
  const { selfHosted } = useSiteContext();
  const { data } = useProperty('nw');
  
  const { navigate } = useTransition();
  
  let isCoListed = false;
  
  if (data && data.acf) {
    isCoListed = data.acf.listing_details.is_co_listed ?? false;
  }
  
  const showAbout = !isCoListed && !hideAboutInRoutes.includes(pathname.replace(/\//g, ''));
  // const showAbout = !hideAboutInRoutes.includes(pathname.replace(/\//g, ''));

  const handleLearnMore = useCallback((e) => {
    e.preventDefault();
    if (!selfHosted) {
      navigate('/about');
    } else {
      window.open('https://www.nealwardproperties.com/about', '_blank');
    }
  }, [navigate, selfHosted]);

  return (
    <>
      {showAbout && (
        <section data-scroll-snap className={`${styles.About} stitch`}>
          <header>
            <h3 className="color-white no-margin">About</h3>
          </header>
          <div className={styles.ImageContainer}>
            <img className={styles.NealImage} src="https://s3-us-west-1.amazonaws.com/nealward-wp-offload/content/uploads/2018/11/09135533/NW-Headshot-1600x1067.jpg" alt="Neal outdoors" />
          </div>
          <div className={styles.Contact}>
            <p className="title color-white">Neal Ward</p>
            <p className={`${styles.tagline} color-white`}>Drive. Heart. Expertise.</p>
            <p className={`${styles.Bio} color-white no-margin`}>These are what we bring to every client relationship. These are the principles that define us. Because while homes are our passion, the people are always the reason. We are Neal Ward Properties.</p>
            <Button onClick={handleLearnMore}>Learn More</Button>
            <div className={styles.Info}>
              <div>
                <p className="contact-title">Neal Ward Properties</p>
                <span>
                  <strong>Address</strong>
                  <address>891 Beach Street<br/>San Francisco, CA 94109</address>
                </span>
                <span>
                  <strong>DRE</strong>
                  <span>01052285</span>
                </span>
              </div>
              <div>
                <p className="contact-title">Contact</p>
                <span>
                  <strong>Mobile</strong>
                  <a href="tel:415-269-9933">415.269.9933</a>
                </span>
                <span>
                  <strong>Email</strong>
                  <a href="mailto:neal@compass.com">neal@compass.com</a>
                </span>
              </div>
            </div>
            <p className={styles.Disclaimer}>Compass is a real estate broker licensed by the State of California and abides by Equal Housing Opportunity laws. License Number 01052285. All material presented herein is intended for informational purposes only and is compiled from sources deemed reliable but has not been verified. Changes in price, condition, sale or withdrawal may be made without notice. No statement is made as to accuracy of any description. All measurements and square footage are approximate.</p>
          </div>
        </section>
      )}
      <footer className={styles.Footer}>
        <Logo className={styles.Logo} />
        <CompassLogo className={styles.CompassLogo} />
        <span className={styles.Copyright}>&copy; {new Date().getFullYear()} Neal Ward</span>
      </footer>      
    </>
  );
};

export default Footer;
