import React, { lazy, Suspense, useState } from 'react';
import { default as Slick } from 'react-slick';
import { LongArrowIcon } from '../../icons';

import styles from './FloorPlanSection.module.scss';

const PSGallery = lazy(() => import('../MobileGallery/PSGallery'));

const ControlArrow = ({className, onClick}) => (
  <button className={className} onClick={onClick}>
    <LongArrowIcon stroke={'black'} />
  </button>
);

const FloorPlanSection = ({ images, pdf, ...props }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [activeIdx, setActiveIdx] = useState();

  const onSelectPlan = (idx) => {
    setActiveIdx(idx);
    setShowGallery(true);
  };

  const slickSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <ControlArrow />,
    prevArrow: <ControlArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 425,
        settings: {
          arrows: false,
          slidesToShow: 1.5,
        }
      }
    ]
  };

  return (
    <section {...props} className={`${styles.Container} bg-white`}>
      <header>
        <h2>Floor Plans</h2>
      </header>
      <Slick {...slickSettings}>
        {images.map(({ ID, alt, sizes }, idx) => (
          <div
            key={`image_${ID}`}
            className={styles.ImageWrapper}
            onClick={() => onSelectPlan(idx)}
          >
            <img src={sizes.medium} alt={alt} />
            <span className="material-icons">add</span>
          </div>
        ))}
      </Slick>
      {pdf && (
        <a href={pdf.url} target="_blank" rel="noopener noreferrer" className={styles.DownloadPDF}>
          <span className="material-icons">file_download</span>
        </a>
      )}
      { showGallery && (
        <Suspense fallback={<p className={styles.Loading}>...</p>}>
          <PSGallery
            images={images}
            activeIdx={activeIdx}
            theme="light"
            onClose={() => setShowGallery(false)}
          />
        </Suspense>
      )}
    </section>
  );
};

export default FloorPlanSection;
