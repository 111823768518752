import React from 'react';

import styles from './VideoPlay.module.scss';

const VideoPlay = ({ className, ...props }) => {
  return (
    <div {...props} className={`${className} ${styles.Container}`}>
      <svg width="30" height="35" viewBox="0 0 30 35" fill="none">
        <path d="M1.70539 2.22116L28.2822 17.5653L1.70539 32.9095L1.70539 2.22116Z" stroke="#009ADE" strokeWidth="1.5"/>
      </svg>
    </div>
  );
};

export default VideoPlay;
