import React from 'react';
import theme from '../theme';

const ArrowIcon = ({ short = false, color = 'white' }) => {
  const { colors } = theme;

  if (!short) {
    return (
      <svg width="26" height="11" viewBox="0 0 26 11" fill="none">
        <line y1="5.5" x2="25" y2="5.5" stroke={colors[color]}/>
        <path d="M20 1L25 5.5L20 10" stroke={colors[color]}/>
      </svg>
    );
  }
  return (
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none">
      <line x1="4.37114e-08" y1="5.5" x2="14" y2="5.5" stroke={colors[color]}/>
      <path d="M9 1L14 5.5L9 10" stroke={colors[color]}/>
    </svg>
  )
};

export default ArrowIcon;
