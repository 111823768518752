import React from 'react';
import { default as ReactSelect } from 'react-select';
import clsx from 'clsx';
import styles from './Select.module.scss';

const DropdownIndicator = ({ innerRef, innerProps }) => (
  <span
    ref={innerRef}
    {...innerProps}
    className="material-icons"
  >keyboard_arrow_down</span>
)

const ClearIndicator = ({ innerRef, innerProps }) => {
  return (
    <span
      ref={innerRef}
      {...innerProps}
      className={`${styles.ClearIndicator} material-icons`}
    >close</span>
  );
}

const Select = ({ variant, className, ...props }) => {

  return (
    <ReactSelect
      classNamePrefix="nwp-select"
      className={clsx(styles.Select, className, {
        small: variant === 'small'
      })}
      isSearchable={false}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
        ClearIndicator,
      }}
      {...props}
    />
  );
};

export default Select;
