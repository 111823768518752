import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';

const Accordion = withStyles((theme, ...rest) => ({
  root: {
    borderBottom: `1px solid ${theme.colors.blue}`,
    color: theme.colors.white,
    background: 'none',
    boxShadow: 'none',
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

export default Accordion;
