import { useQuery } from 'react-query';
import axios from 'axios';
import { useSiteContext } from '../../state/SiteContext';

const getProperty = async ({ queryKey }) => {
  const [, { name, password, domain }] = queryKey;
  const params = Object.entries({
    pass: password,
    domain,
    'skip_cache': password ? 1 : 0 
  })
    .filter(([key, val]) => !!val)
    .map(([key, val]) => `${key}=${encodeURI(val)}`)
    .join('&');
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/property/${name}?${params}`
  );
  return data;
};

export default function useProperty(name) {
  const { domain, selfHosted, password } = useSiteContext();
  return useQuery(['property', {
    name, password, domain: selfHosted ? domain : null,
  }], getProperty);
};
