import React, { useContext, useState } from 'react';
import SnackbarContainer from '../partials/SnackbarContainer/SnackbarContainer';

const SnackContext = React.createContext({
  snackOpen: false,
  onClose: () => undefined,
  showSnack: () => undefined,
});

export const SnackContextProvider = ({ children }) => {
  const [snackOpen, setSnackOpen] = useState(false);
  const onClose = (e, reason) => {
    if (reason === 'clickaway') return;
    setSnackOpen(false);
  }

  const showSnack = () => {
    setSnackOpen(true);
  }

  return (
    <SnackContext.Provider value={{
      snackOpen,
      onClose,
      showSnack,
    }}>
      {children}
      <SnackbarContainer />
    </SnackContext.Provider>
  );
}

export const useSnackContext = () => useContext(SnackContext);

export default SnackContext;