import React, { useState, useEffect, useRef, useMemo } from 'react';
import gsap from 'gsap';
import clsx from 'clsx';
import { Transition } from 'react-transition-group';

import styles from './Slideshow.module.scss';

// <img width="2884" height="2166" src="https://dev.nealwardproperties.com/uploads/2020/09/2801NOakleyAve105.png" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" srcset="https://dev.nealwardproperties.com/uploads/2020/09/2801NOakleyAve105.png 2884w, https://dev.nealwardproperties.com/uploads/2020/09/2801NOakleyAve105-300x225.png 300w, https://dev.nealwardproperties.com/uploads/2020/09/2801NOakleyAve105-1024x769.png 1024w, https://dev.nealwardproperties.com/uploads/2020/09/2801NOakleyAve105-768x577.png 768w, https://dev.nealwardproperties.com/uploads/2020/09/2801NOakleyAve105-1600x1202.png 1600w" sizes="(max-width: 2884px) 100vw, 2884px" />

const Image = ({ data, active = false }) => {
  const imageObj = useRef();
  const [ready, setReady] = useState(false);
  const {
    alt,
    sizes: { 
      xl: src,
      'xl-width': width,
      'xl-height': height,
    },
  } = data;
  const isVertical = height > width;

  const imageStyles = useMemo(() => {
    if (!isVertical || !ready) return {};
    return {
      backgroundImage: `url(${imageObj.current.src})`,
    };
  }, [isVertical, ready]);

  useEffect(() => {
    imageObj.current = new window.Image();
    imageObj.current.onload = () => setReady(true);
    imageObj.current.src = src;
  }, [src]);

  return (
    <Transition
      unmountOnExit
      mountOnEnter
      in={active && ready}
      onEnter={(node) => {
        gsap.set(node, {
          autoAlpha: 0,
          zIndex: 1,
        });
      }}
      addEndListener={(node, done) => {
        gsap.set(node, { zIndex: active ? 1 : 0 });
        gsap.to(node, {
          duration: 0.8,
          autoAlpha: 1,
          onComplete: done,
          ease: 'Power3.out',
        });
      }}
    >
      <div
        className={clsx(styles.ImageWrapper, {
          portrait: isVertical,
        })}
      >
        {ready && (
          <>
            {isVertical && (
              <div style={imageStyles} className={styles.ImageBlurred} />
            )}
            <img src={imageObj.current.src} alt={alt} />
          </>
        )}
      </div>
    </Transition>
  );
}

export default Image;
