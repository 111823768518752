import React, { useState } from 'react';
import { VideoPlayer, VideoPlay } from '../../components';

import styles from './VideoSection.module.scss';

const VideoSection = ({ video, featured_image, ...props }) => {
  const [showVideo, setShowVideo] = useState(false);

  const handleClose = () => {
    setShowVideo(false);
  };

  return (
    <div
      {...props}
      className={styles.VideoContainer}
      style={{
        backgroundImage: `url(${featured_image.sizes.xl})`,
      }}
    >
      <VideoPlay className={styles.VideoPlay} onClick={() => setShowVideo(true)} />
      {showVideo && (
        <VideoPlayer video={video} onClose={handleClose} />
      )}
    </div>
  );
};

export default VideoSection;
