import React, { useMemo, lazy, Suspense } from 'react';
import { Select } from '../../components';
import useMediaQuery from '../../hooks/useMediaQuery';
import { statusOptions, priceOptions, sortOptions } from '../../data/filters';
import { useFilters } from '../../views/AllProperties/FilterContext';

import styles from './PropertyFilter.module.scss';

const MobileFilters = lazy(() => import('./MobileFilters'));

const PropertyFilter = ({ total, totalPages }) => {
  const { isTablet } = useMediaQuery();
  const {
    createPageOptions,
    page,
    setPage,
    price,
    setPrice,
    status,
    setStatus,
    sortBy,
    setSortBy
  } = useFilters();

  const pageOptions = useMemo(() => {
    if (totalPages) return createPageOptions(totalPages);
    return null;
  }, [createPageOptions, totalPages]);

  const showingMax = page ? Math.min(page * 12, total) : 0;
  const showingMin = page ? (page - 1) * 12 + 1 : 0;

  return (
    <div className={`${styles.Filters} color-white`}>
      {total && (
        <p className="no-margin">Showing {showingMin}-{showingMax} of {total} Listings</p>
      )}
      {isTablet ? (
        <>
          <Select
            aria-label="Sorty By"
            name="sort"
            placeholder="Sort By"
            className={styles.Select}
            options={sortOptions}
            value={sortBy}
            onChange={(e) => {
              if (totalPages) {
                setPage(1);
              }
              setSortBy(e);
            }}
          />
          <Select
            aria-label="Filter By Price"
            name="price"
            placeholder="Price"
            className={styles.Select}
            isClearable
            options={priceOptions}
            value={price}
            onChange={(e) => {
              if (totalPages) {
                setPage(1);
              }
              setPrice(e);
            }}
          />
          <Select
            aria-label="Filter By Status"
            name="status"
            placeholder="Status"
            className={styles.Select}
            isClearable
            options={statusOptions}
            value={status}
            onChange={(e) => {
              if (totalPages) {
                setPage(1);
              }
              setStatus(e);
            }}
          />
          {totalPages > 1 && (
            <>
              <Select
                aria-label="Page"
                name="page"
                placeholder="Page"
                className={styles.Select}
                variant="small"
                options={pageOptions}
                value={pageOptions[page - 1]}
                onChange={(e) => setPage(Number(e.value))}
              />
              <span className={styles.TotalPages}>of {totalPages}</span>
            </>
          )}
        </>
      ) : (
        <Suspense fallback={<p>Loading...</p>}>
          <MobileFilters className={styles.MobileFilters} />
        </Suspense>
      )}
    </div>
  );
};

export default PropertyFilter;