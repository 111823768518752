import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button } from '../../components';
import { LongArrowIcon } from '../../icons';
import Image from './Image';
import Thumbnails from './Thumbnails';
import Caption from './Caption';

import styles from './Slideshow.module.scss';
import clsx from 'clsx';

const Slideshow = ({ images, ...props }) => {
  const [activeIdx, setActiveIdx] = useState(0);
  const [showThumbs, setShowThumbs] = useState(false);
  const [showCaption, setShowCaption] = useState(false);
  const slideshowRef = useRef();

  const prevImage = useCallback(() => {
    setActiveIdx(prev => prev > 0 ? prev - 1 : images.length - 1);
  }, [images.length]);
  
  const nextImage = useCallback(() => {
    setActiveIdx(prev => prev < images.length - 1 ? prev + 1 : 0);
  }, [images.length]);

  const onKeydown = useCallback((e) => {
    if (e.code === 'ArrowRight') nextImage();
    if (e.code === 'ArrowLeft') prevImage();
  }, [nextImage, prevImage]);

  const toggleThumbs = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowThumbs((prev) => !prev);
  }, []);

  const activeImageCaption = useMemo(() => {
    return images[activeIdx].caption;
  }, [activeIdx, images]);

  useEffect(() => {
    setShowCaption(false);
  }, [activeIdx, activeImageCaption]);

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    }
  }, [onKeydown]);

  useEffect(() => {
    const links = images.map((img) => {
      const link = document.createElement('link');
      link.setAttribute('rel', 'prefetch');
      link.setAttribute('href', img.sizes.xl);

      document.head.append(link);
      return link;
    });

    return () => {
      links.forEach(link => link.remove());
    }
  }, [images]);

  return (
    <section {...props} ref={slideshowRef} className={styles.Container}>
      <div className={styles.ImageContainer}>
        {images && images.map((image, idx) => (
          <Image key={image.ID} data={image} active={idx === activeIdx} />
        ))}
      </div>
      <div className={styles.PrevNext}>
        <span className="prev" onClick={prevImage} />
        <span className="next" onClick={nextImage} />
      </div>
      <div className={`${styles.Controls} color-white`}>
        <Button
          variant="icon"
          aria-label="Caption"
          className={clsx({hide: isEmpty(activeImageCaption)})}
          onClick={() => setShowCaption(!showCaption)}>
          <span className="material-icons-outlined">{!showCaption ? 'info' : 'close'}</span>
        </Button>
        <div className={styles.Navigation}>
          <Button variant="icon" aria-label="Previous" className={styles.PrevButton} onClick={prevImage}>
            <LongArrowIcon />
          </Button>
          <p>{activeIdx + 1} / {images.length}</p>
          <Button variant="icon" aria-label="Next" className={styles.NextButton} onClick={nextImage}>
            <LongArrowIcon />
          </Button>
        </div>
        {showThumbs ? (
          <Button variant="icon" aria-label="close" onClick={toggleThumbs}>
            <span className="material-icons">close</span>
          </Button>
        ) : (
          <Button variant="icon" aria-label="Thumbnails" onClick={toggleThumbs}>
            <span className="material-icons">apps</span>
          </Button>
        )}
      </div>
      <Caption
        text={activeImageCaption}
        show={showCaption}
      />
      <Thumbnails
        images={images}
        showThumbs={showThumbs}
        activeIdx={activeIdx}
        onSelectThumb={idx => setActiveIdx(idx)}
        onClose={() => setShowThumbs(false)}
      />
    </section>
  );
};

export default Slideshow;
