import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';
import clsx from 'clsx';

import styles from './Modal.module.scss';

const Modal = ({children, onClose, lightbox = false, open = false}) => {
  useEffect(() => {
    if (!open) return;
    
    const root = document.getElementById('ModalPortal');
    const listenForEscape = (e) => {
      e.preventDefault();
      if (e.key === 'Escape') onClose();
    }
    const preventScroll = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    window.addEventListener('keydown', listenForEscape);
    root.addEventListener('wheel', preventScroll);

    return () => {
      window.removeEventListener('keydown', listenForEscape);
      root.removeEventListener('wheel', preventScroll);
    }
  }, [onClose, open]);

  return ReactDOM.createPortal((
    <Transition
      in={open}
      mountOnEnter
      unmountOnExit
      addEndListener={(node, done) => {
        gsap.set(node, { autoAlpha: open ? 0 : 1 });
        gsap.to(node, {
          autoAlpha: open ? 1 : 0,
          duration: 0.26,
          ease: 'Power3.easeOut',
          onComplete: done,
        });
      }}
    >
      <div className={clsx(styles.Container, { lightbox })}>
        <span className="nw-glass dark" />
        <div className={styles.Content}>
          {children}
          <button className={styles.CloseButton} onClick={onClose}>
            <span className="material-icons">close</span>
          </button>
        </div>
      </div>
    </Transition>
  ), document.getElementById('ModalPortal'));
};

export default Modal;
