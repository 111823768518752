import React from 'react';
import useProperty from '../../hooks/data/useProperty';

import styles from './Header.module.scss';

const CoListLogos = ({ dark = false }) => {
  const { data = {}, status } = useProperty('nw');
  const {
    acf: {
      co_listed_details: {
        agents = [],
      } = {},
    } = {},
  } = data;
  
  return status === 'loading' ? null : (
    <div className={styles.CoListLogos}>
      {agents.map(({agent: {post_title: name, acf: {light_logo, dark_logo, website}}}) => (
        <>
          {light_logo && dark_logo && (
            <a href={website} target="_blank" rel="noopener noreferrer" key={name.replace(/ /g, '')}>
              {dark ? (
                <img src={dark_logo.sizes.medium} alt={dark_logo.alt} />
                ) : (
                <img src={light_logo.sizes.medium} alt={light_logo.alt} />
              )}
            </a>
          )}
        </>
      ))}
    </div>
  );
};

export default CoListLogos;
