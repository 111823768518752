import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import clamp from 'lodash/clamp';
import { SearchIcon } from '../../icons';
import useSearch from '../../hooks/data/useSearch';
import { useTransition } from '../../state/TransitionContext';

import styles from './SearchBar.module.scss';

const SearchBarModal = ({ onClose = noop, onSelect = noop }) => {
  const [value, setValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectIndex, setSelectIndex] = useState(-1);
  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const { navigate } = useTransition();
  const {data, refetch, status} = useSearch(searchTerm);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchTerm = useCallback(debounce(setSearchTerm, 200), []);

  const onModalClick = useCallback((e) => {
    if (e.target === modalRef.current || !modalRef.current.contains(e.target)) {
      onClose();
    }
  }, [onClose]);

  const onKeyDown = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      const dir = e.key === 'ArrowDown'? 1 : -1;
      setSelectIndex((idx) => {
        return clamp(idx + dir, 0, data.length - 1);
      });
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      if (selectIndex !== -1) {
        navigate(`/property/${data[selectIndex].post_name}`);
      } else {
        onSelect(value);
      }
      onClose();
    }
  };

  useEffect(() => {
    setSelectIndex(-1);
    refetch();
  }, [refetch, searchTerm]);

  useEffect(() => {
    requestAnimationFrame(() => {
      inputRef.current.focus();
    });
  }, []);

  return ReactDOM.createPortal((
    <div
      className={styles.Modal}
      onClick={onModalClick}
      onKeyDown={onKeyDown}
      ref={modalRef}
    >
      <div className={styles.Container}>
        <div className={styles.Wrapper}>
          <div className="input-group">
            <span className={styles.SearchIcon}><SearchIcon /></span>
            <input
              ref={inputRef}
              type="text"
              id="searchbar"
              placeholder="Search Our Properties"
              autoComplete="off"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                debounceSetSearchTerm(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <ul className={styles.Results}>
        {data && data.length > 0 ? (
          data.map((result, idx) => (
            <li
              key={result.ID}
              onMouseOver={() => setSelectIndex(idx)}
              onClick={() => {
                navigate(`/property/${data[idx].post_name}`);
                onClose();
              }}
              className={clsx({
                selected: idx === selectIndex,
              })}
            >{result.post_title}</li>
          ))
        ) : status === 'success' && searchTerm !== '' && (
          <li className="no-match">No Properties Found</li>
        )}
      </ul>
    </div>
  ), document.getElementById('ModalPortal'));
};

export default SearchBarModal;
