import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useFilters } from '../../views/AllProperties/FilterContext';
import { Button, Select } from '../../components';

import styles from './Pagination.module.scss';

const Pagination = ({total, totalPages}) => {
  const { page, setPage, createPageOptions } = useFilters();
  const pageOptions = useMemo(() => {
    if (totalPages) return createPageOptions(totalPages);
    return null;
  }, [createPageOptions, totalPages]);

  const nextPage = useCallback(() => {
    setPage((curr) => {
      if (curr < totalPages) return curr + 1;
      return curr;
    });
  }, [setPage, totalPages]);
  
  const prevPage = useCallback(() => {
    setPage((curr) => {
      if (curr > 1) return curr - 1;
      return curr;
    });
  }, [setPage]);

  const showingMax = page ? Math.min(page * 12, total) : 0;
  const showingMin = page ? (page - 1) * 12 + 1 : 0;

  return (
    <div className={clsx(styles.Container, 'color-white')}>
      <div className={styles.Inner}>
        <p className="no-margin">Showing {showingMin}-{showingMax} of {total} Listings</p>
        <div className={styles.PrevNext}>
          <Button className={styles.Button} disabled={page === 1} onClick={prevPage}>
            Previous
          </Button>
          <p className="no-margin">{page} of {totalPages}</p>
          <Button className={styles.Button} disabled={page === totalPages} onClick={nextPage}>
            Next
          </Button>
        </div>
        <div className={styles.PageSelect}>
          <Select
            name="page"
            placeholder="Page"
            variant="small"
            options={pageOptions}
            value={pageOptions[page - 1]}
            onChange={(e) => setPage(Number(e.value))}
          />
          <span>of {totalPages}</span>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
