import React from 'react';
import clsx from 'clsx';

import styles from './ContactListItem.module.scss';

const ContactListItem = ({className, info}) => {
  const {
    brokerage,
    name,
    email,
    phone,
    license,
  } = info;

  return (
    <div className={clsx(styles.Container, className)}>
      <h3>{name}</h3>
      {email && (
        <span>
          <strong>Email</strong>
          <span>{email}</span>
        </span>
      )}
      {phone && (
        <span>
          <strong>Phone</strong>
          <span>{phone}</span>
        </span>
      )}
      {brokerage && (
        <span>
          <strong>Brokerage</strong>
          <span>{brokerage}</span>
        </span>
      )}
      {license && (
        <span>
          <strong>DRE</strong>
          <span>{license}</span>
        </span>
      )}
    </div>
  );
};

export default ContactListItem;
