import React from 'react';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';

import styles from './Slideshow.module.scss';

const Caption = ({show = false, text}) => {
  return (
    <Transition
      in={show}
      mountOnEnter
      addEndListener={(node, done) => {
        gsap.set(node, { autoAlpha: show ? 0 : 1 });
        gsap.to(node, {
          autoAlpha: show ? 1 : 0,
          duration: 0.25,
          onComplete: done,
        });
      }}
    >
      <div className={`${styles.Caption} color-white`}>
        <p>{text}</p>
      </div>

    </Transition>
  );
};

export default Caption;
