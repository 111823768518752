import React, { useContext, useState } from 'react';

const SiteContext = React.createContext({
  host: '',
  domain: '',
  selfHosted: false,
  password: null,
});

export function SiteContextProvider({ children }) {
  // const host = 'www.435ralston.com';
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const host = window.location.hostname;
  const domain = host.split('.').splice(-2).join('.');
  const [password, setPassword] = useState(params.ppwp_ac);
  const selfHosted = ![
    'localhost',
    'nealwardproperties.com'
  ].some((d) => host.includes(d));

  return (
    <SiteContext.Provider value={{
      host,
      domain,
      selfHosted,
      password,
      setPassword,
    }}>
      {children}
    </SiteContext.Provider>
  );
}

export function useSiteContext() {
  return useContext(SiteContext);
}

export default SiteContext;
