import React, { useEffect, useMemo, lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import {
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { SiteContextProvider, useSiteContext } from './state/SiteContext';
import { MenuContextProvider, useMenu } from './state/MenuContext';
import { TransitionContextProvider } from './state/TransitionContext';
import { LocoRouter as Router } from './components';
import Header from './components/Header/Header';
import { Footer } from './partials';
import theme from './theme';
import styles from './App.module.scss';
import { SnackContextProvider } from './state/SnackContext';

const Home = lazy(() => import('./views/Home'));
const AllProperties = lazy(() => import('./views/AllProperties/AllProperties'));
const Property = lazy(() => import('./views/Property/Property'));
const About = lazy(() => import('./views/About/About'));
const Contact = lazy(() => import('./views/Contact/Contact'));
const CustomPage = lazy(() => import('./views/CustomPage/CustomPage'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 10,
    }
  }
});

function NWApp() {
  const { selfHosted } = useSiteContext();
  const { isMenuOpen } = useMenu();

  const routes = useMemo(() => {
    return !selfHosted ? [
      <Route key="home" exact path="/" component={Home} />,
      <Route key="properties" exact path="/properties" component={AllProperties} />,
      <Route key="property" exact path="/property/:slug" component={Property} />,
      <Route key="about" exact path="/about" component={About} />,
      <Route key="contact" exact path="/contact" component={Contact} />,
      <Route key="custom" exact path="/:slug" component={CustomPage} />,
    ] : [
      <Route exact key="property" path="/" component={Property} />
    ]
  }, [selfHosted]);

  const renderDevTools = () => createPortal(
    <ReactQueryDevtools initialIsOpen={false} />,
    document.getElementById('DevPortal')
  );

  useEffect(() => {
    document.documentElement.classList.toggle('menu-open', isMenuOpen);
  }, [isMenuOpen]);

  return (
    <div id="NWP_App" className={styles.App}>
      <QueryClientProvider client={queryClient}>
        {renderDevTools()}
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              <TransitionContextProvider>
                <SnackContextProvider>
                  <Suspense fallback={<div className={styles.LazyLoading}>Loading...</div>}>
                    <Switch>
                      {routes}
                      <Redirect to="/" />
                    </Switch>
                  </Suspense>
                  <Footer />
                  <Header />
                </SnackContextProvider>
              </TransitionContextProvider>
            </Router>
          </ThemeProvider>
        </StylesProvider>
      </QueryClientProvider>
    </div>
  );
}

const App = () => (
  <SiteContextProvider>
    <MenuContextProvider>
      <NWApp />
    </MenuContextProvider>
  </SiteContextProvider>
);

export default App;
