import React, { useRef } from 'react';
import { default as Slick } from 'react-slick';
import { useTransition } from '../../state/TransitionContext';
import { ArrowIcon } from '../../icons';
import Slide from './Slide';

import styles from './Slider.module.scss';

const ControlArrow = ({className, style, onClick, label}) => (
  <button aria-label={label} className={className} style={{...style}} onClick={onClick}>
    <ArrowIcon short color="blue" />
  </button>
);

const Slider = ({ properties, title = 'More Properties', status, ...props }) => {
  const { navigate } = useTransition();
  const slickRef = useRef(null);

  const slickSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 4.25,
    slidesToScroll: 1,
    nextArrow: <ControlArrow label="Next" />,
    prevArrow: <ControlArrow label="Previous" />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3.25,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.25,
        }
      },
      {
        breakpoint: 425,
        settings: {
          arrows: false,
          slidesToShow: 1.1,
        }
      }
    ]
  };

  return (
    <section {...props} className={`${styles.Container} stitch bg-white`}>
      <div>
        <header>
          <h3>{title}</h3>
        </header>
        <Slick {...slickSettings} ref={slickRef}>
          {properties?.map(({ property }, idx) => (
            <Slide
              property={property}
              onClick={() => navigate(`/property/${property.post_name}`)}
              key={property.ID}
            />
          ))}
        </Slick>
      </div>
    </section>
  );
};

export default Slider;
