import React, { useRef, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import isEmpty from 'lodash/isEmpty';
import { useMenu } from '../../state/MenuContext';
import { useLoco } from '../../state/LocoContext';
import useProperty from '../../hooks/data/useProperty';
import useMediaQuery from '../../hooks/useMediaQuery';
import BackgroundVideo from '../../components/BackgroundVideo';
import { PropertyDetails } from '../';
import {
  BackgroundImage,
  SeeMore,
} from '../../components';

import styles from './PropertyTitle.module.scss';

const PropertyTitle = () => {
  const { slug = 'nw' } = useParams();
  const heroRef = useRef();
  const { isMenuOpen } = useMenu();
  const { isTouchDevice } = useMediaQuery();
  const { scrollTo } = useLoco();
  const [ videoPaused, setVideoPaused ] = useState(isMenuOpen);
  const {
    data: propertyData = {}, status,
  } = useProperty(slug);

  const {
    acf: {
      background_video,
      listing_details: {
        neighborhood,
        title,
      } = {},
    } = {},
    featured_image,
  } = propertyData;

  useLayoutEffect(() => {
    if (status === 'loading') return;

    let videoTrigger;
    let tl;

    setTimeout(() => {
      videoTrigger = ScrollTrigger.create({
        trigger: heroRef.current,
        scroller: !isTouchDevice ? '#root' : document,
        start: 'top top',
        end: 'center top+=100px',
        onEnter: () => setVideoPaused(false),
        onLeave: () => setVideoPaused(true),
        onEnterBack: () => setVideoPaused(false),
      });
  
      tl = gsap.timeline({
        scrollTrigger: {
          trigger: heroRef.current,
          scroller: !isTouchDevice ? '#root' : document,
          start: 'top top',
          end: 'bottom top',
          scrub: true,
        }
      });
  
      const parallax = heroRef.current.querySelectorAll('[data-scroll-speed]');
  
      parallax.forEach((node) => {
        const speed = parseInt(node.dataset.scrollSpeed) / 10;
        tl.to(node, {
          y: -(heroRef.current.clientHeight * speed),
        }, 0);
      });
    });

    return () => {
      videoTrigger.kill(true);
      tl.kill(true);
    }
  }, [isTouchDevice, status]);

  if (status !== 'success') return null;

  return (
    <section className={styles.Container} ref={heroRef}>
      <BackgroundImage image={featured_image} />
      {background_video && !isEmpty(background_video.video_url) && (
        <BackgroundVideo
          video={background_video.video_url}
          start={background_video.start_time}
          end={background_video.end_time}
          paused={videoPaused}
          placeholder={featured_image.sizes['medium']}
        />
      )}
      <div data-scroll-snap className={styles.Title}>
        <div data-scroll-speed="-2" className={styles.TitleHeader}>
          {neighborhood && <p className="eyebrow color-white">{neighborhood}</p>}
          <h1>{title}</h1>
        </div>
        <SeeMore
          data-scroll-speed="-4"
          className={clsx(styles.SeeMore)}
          onClick={() => scrollTo(0, window.innerHeight)}
        />
      </div>
      <PropertyDetails
        data-scroll-snap
      />
    </section>
  );
};

export default PropertyTitle;
