import React, { useLayoutEffect, useCallback, useContext, useRef, useEffect } from 'react';
import Scrollbar from 'smooth-scrollbar';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import useMediaQuery from '../hooks/useMediaQuery';
import NativeScroll from './NativeScroll';

gsap.registerPlugin(ScrollTrigger);

const LocoContext = React.createContext({
  isMenuOpen: false,
  setIsMenuOpen: () => {},
  toggleMenu: () => {},
});

export function LocoContextProvider({ children }) {
  const scrollbar = useRef();
  const { isTouchDevice } = useMediaQuery();

  const scrollTo = useCallback((x, y, duration = 600, options = {}) => {
    scrollbar.current.scrollTo(x, y, duration, options);
  }, []);
  
  const addListener = useCallback((listener) => {
    scrollbar.current.addListener(listener);
  }, [scrollbar]);
  
  const removeListener = useCallback((listener) => {
    scrollbar.current.removeListener(listener);
  }, [scrollbar]);
  
  const update = useCallback(() => {
    scrollbar.current.update();
  }, []);

  const scrollTop = useCallback(() => {
    return scrollbar.current.scrollTop;
  }, []);
  
  const scrollIntoView = useCallback((elem, options = {}) => {
    return scrollbar.current.scrollIntoView(elem, options);
  }, []);
  
  useLayoutEffect(() => {
    let sb;

    if (!isTouchDevice) {
      const scrollContainer = document.getElementById('root');
      
      sb = Scrollbar.init(scrollContainer, {
        damping: !isTouchDevice ? 0.3 : 0.1,
        delegateTo: document,
      });
  
      ScrollTrigger.scrollerProxy(scrollContainer, {
        scrollTop(value) {
          if (arguments.length) {
            scrollbar.current.scrollTop = value;
          }
          return scrollbar.current.scrollTop;
        },
        getBoundingClientRect() {
          return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
        }
      });
  
    } else {
      sb = new NativeScroll();
    }

    scrollbar.current = sb;
    scrollbar.current.addListener(ScrollTrigger.update);

    return () => {
      if (sb) sb.destroy();
    }
  }, [isTouchDevice]);

  useEffect(() => {
    Scrollbar.detachStyle();
  });
  return (
    <LocoContext.Provider value={{
      addListener,
      removeListener,
      scrollIntoView,
      scrollTo,
      scrollTop,
      update,
    }}>
      {children}
    </LocoContext.Provider>
  )
}

export function useLoco() {
  return useContext(LocoContext);
}

export default LocoContext;
