import React, { useCallback, useEffect, useRef, useState, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useSiteContext } from '../../state/SiteContext';
import { useMenu } from '../../state/MenuContext';
import { useTransition } from '../../state/TransitionContext';
import { useLoco } from '../../state/LocoContext';
import useProperty from '../../hooks/data/useProperty';
import { ReactComponent as NealWardLogo } from '../../logo.svg';
import CoListLogos from './CoListLogos';

import styles from './Header.module.scss';

const Menu = lazy(() => import('../Menu'));

const Header = () => {
  const { selfHosted } = useSiteContext();
  const { isMenuOpen, toggleMenu } = useMenu();
  const { navigate } = useTransition();
  const headerRef = useRef();
  const [yOffset, setYOffset] = useState(0);
  const [shrink, setShrink] = useState(false);
  const { addListener, removeListener } = useLoco();
  const { goBack } = useHistory();
  const { pathname, state } = useLocation();
  const { data } = useProperty('nw');

  const showBackButton = state?.from && pathname.includes('/property');

  let isCoListed = false;
  
  if (data && data.acf) {
    isCoListed = data.acf.listing_details.is_co_listed ?? false;
  }

  const getNameByRoute = useCallback((route) => {
    let name;

    switch(route) {
      case '/':
        name = 'Home';
        break;
      case '/properties/':
        name = 'All Properties';
        break;
      default:
        name = '';
    }

    return name;
  }, []);

  const handleBrandClick = useCallback((e) => {
    e.preventDefault();
    if (!selfHosted) {
      navigate('/');
    } else {
      window.open('https://www.nealwardproperties.com', '_blank');
    }
  }, [navigate, selfHosted]);

  const headerStyles = {
    transform: `translateY(${-yOffset}px)`,
  };

  useEffect(() => {
    let prevY = 0;

    const listener = ({ offset }) => {
      const deltaY = offset.y > 0 ? offset.y - prevY : 0;
      const maxValue = headerRef.current.offsetHeight * 4;
      const minValue = 0;

      setShrink(() => offset.y >= 100);

      setYOffset((prev) => {
        if (offset.y <= 0) return 0;
        
        const next = prev + deltaY;
        if (next > maxValue) return maxValue;
        if (next <= minValue) return minValue;
        return next;
      });
      prevY = offset.y;
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    }
  }, [addListener, removeListener]);

  return ReactDOM.createPortal((
    <>
      <header className={clsx(styles.Nav, { shrink })} style={headerStyles} ref={headerRef}>
        {isCoListed && <CoListLogos dark={shrink} />}
        <div tabIndex={0} className={styles.Brand} onClick={handleBrandClick}>
          <NealWardLogo className={styles.Logo} />
        </div>
        {!selfHosted && (
          <button
            tabIndex={0}
            className={clsx(styles.Hamburger, { [styles.open]: isMenuOpen})}
            onClick={() => toggleMenu(!isMenuOpen)}
            aria-label="Menu"
          >
            <span />
            <span />
            <span />
          </button>
        )}
        {showBackButton && (
          <button className={clsx(styles.BackButton, 'color-white')} onClick={goBack}>
            <span className="material-icons">arrow_back</span>
            <span>Back to {getNameByRoute(state.from)}</span>
          </button>
        )}
      </header>
      {!selfHosted && (
        <Suspense fallback={<p>Loading...</p>}>
          <Menu />
        </Suspense>
      )}
    </>
  ), document.getElementById('NavPortal'));
};

export default Header;