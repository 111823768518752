import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import flow from 'lodash/flow';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import times from 'lodash/times';
import { statusOptions, priceOptions, sortOptions } from '../../data/filters';

const FilterContext = React.createContext({});

export function FilterContextProvider({ children }) {
  const location = useLocation();
  const history = useHistory();
  
  const [searchQuery, setSearchQuery] = useState(location.search);
  const [filters, setFilters] = useState(() => {
    const params = new URLSearchParams(location.search);
    return {
      status: params.get('status') || '',
      price: params.get('price') || '',
      page: params.get('page') || 1,
      sortBy: params.get('sortBy') || 'price:desc',
      search: params.get('s') || '',
    }
  });

  const [page, setPage] = useState(filters.page ? Number(filters.page) : 1);
  const [price, setPrice] = useState(() => {
    if (filters && filters.price) {
      return priceOptions.find(opt => opt.value === filters.price) || null;
    }
    return null;
  });
  const [status, setStatus] = useState(() => {
    if (filters && filters.status) {
      return statusOptions.find(opt => opt.value === filters.status) || null;
    }
    return null;
  });
  const [sortBy, setSortBy] = useState(() => {
    if (filters && filters.sortBy) {
      return sortOptions.find(opt => opt.value === filters.sortBy) || null;
    }
    return null;
  });
  const [searchTerm, setSearchTerm] = useState(filters?.search || '');

  const createPageOptions = useCallback((totalPages) => {
    const numArray = times(parseInt(totalPages), Number);
    return numArray.map(k => ({ value: k + 1 + '', label: k + 1 + '' }));
  }, []);

  const updateQueryParams = useCallback((params) => {
    setSearchQuery((query) => {
      const prev = Object.fromEntries(new URLSearchParams(query));
      const search = Object.fromEntries(new URLSearchParams(params));
      const merged = flow([
        merge,
        (obj) => omitBy(obj, isEmpty),
      ])(prev, search);
      return new URLSearchParams(merged).toString();
    });
  }, []);

  useEffect(() => {
    updateQueryParams({
      page: page || 1,
      price: price?.value || '',
      sortBy: sortBy?.value || '',
      status: status?.value || '',
      s: searchTerm || '',
    })
  }, [page, price, searchTerm, sortBy, status, updateQueryParams]);

  useEffect(() => {
    history.replace({
      search: searchQuery,
    });
  }, [history, searchQuery]);

  const value = {
    createPageOptions,
    filters,
    setFilters,
    page,
    setPage,
    price,
    setPrice,
    status,
    setStatus,
    sortBy,
    setSortBy,
    searchTerm,
    setSearchTerm,
  }

  return(
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  )
}

export function useFilters() {
  return useContext(FilterContext);
}

export default FilterContext;
