import { withStyles } from '@material-ui/core/styles';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: '1.875rem 0',
    padding: 0,
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      width: '100%',
      '& li': {
        fontSize: '1rem',
        lineHeight: '1.5625rem',
        padding: '1.4375rem 0',
        display: 'block',
      },
      '& li + li': {
        borderTop: '1px solid #444',
      }
    },
  },
}))(MuiAccordionDetails);

export default AccordionDetails;
