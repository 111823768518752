import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';
import { SearchIcon } from '../../icons';
import { Button } from '../../components';
import { useFilters } from '../../views/AllProperties/FilterContext';
import SearchBarModal from './SearchBarModal';

import styles from './SearchBar.module.scss';

const SearchBar = () => {
  const [active, setActive] = useState(false);
  const { searchTerm, setSearchTerm, setPage, setStatus } = useFilters();

  return (
    <div className={styles.Container}>
      <div className={styles.Wrapper}>
        <div className="input-group">
          <span className={styles.SearchIcon}><SearchIcon /></span>
          <input
            aria-label="Search Our Properties"
            type="text"
            id="searchbar"
            placeholder="Search Our Properties"
            autoComplete="off"
            value={searchTerm}
            readOnly
            onClick={() => setActive(true)}
          />
          {searchTerm.length && (
            <Button
              variant="icon"
              className={styles.ClearSearch}
              onClick={() => setSearchTerm('')}
            >
              <span className="material-icons">close</span>
            </Button>
          )}
        </div>
      </div>
      <Transition
        mountOnEnter
        unmountOnExit
        in={active}
        onEnter={node => gsap.set(node, {
          autoAlpha: 0,
        })}
        onExit={node => gsap.set(node, {
          autoAlpha: 1,
        })}
        addEndListener={(node, done) => {
          gsap.to(node, {
            autoAlpha: active ? 1 : 0,
            onComplete: done,
            duration: 0.2,
          })
        }}
      >
        <SearchBarModal
          onSelect={(term) => {
            setPage(1);
            setStatus('');
            setSearchTerm(term)
          }}
          onClose={() => setActive(false)}
        />
      </Transition>
    </div>
  );
};

export default SearchBar;
