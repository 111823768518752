import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

const AccordionSummary = withStyles((theme) => ({
  root: {
    minHeight: '2.5rem',
    padding: 0,
    '&$expanded': {
      minHeight: '2.5rem',
    },
  },
  content: {
    fontSize: '0.75rem',
    fontWeight: 700,
    lineHeight: '2.2em',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: {
    '@global': {
      '.material-icons': {
        fontSize: '1rem',
        color: theme.colors.blue,
      }
    },
  },
}))(MuiAccordionSummary);

export default AccordionSummary;
