import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { default as gsap, Power2 } from 'gsap';
import {
  TRANSITION_IN_ENTER,
  TRANSITION_IN_DONE,
  TRANSITION_OUT_ENTER,
  TRANSITION_OUT_DONE,
  useTransition
} from '../state/TransitionContext';
import { ReactComponent as Logo } from '../logo.svg';
import styles from './Curtain.module.scss';

const Curtain = () => {
  const { transitionState, setTransitionState } = useTransition();
  const baseRef = useRef(null);
  const loadingRef = useRef(null);
  const timeline = useRef(null);

  useEffect(() => {
    timeline.current = gsap.timeline({ paused: true });
    timeline.current.fromTo(baseRef.current, 0.35, { scaleX: 0 }, { scaleX: 1, ease: Power2.easeInOut });
    timeline.current.from(loadingRef.current, 0.25, { opacity: 0, y: '+=10' });
    timeline.current.call(setTransitionState, [TRANSITION_IN_DONE]);
    timeline.current.call(setTransitionState, [TRANSITION_OUT_ENTER], '+=0.5');
    timeline.current.to(loadingRef.current, 0.25, { opacity: 0 });
    timeline.current.to(baseRef.current, 0.35, { scaleX: 0, transformOrigin: 'right center', ease: Power2.easeInOut });
    timeline.current.call(setTransitionState, [TRANSITION_OUT_DONE]);
    timeline.current.call(setTransitionState, [null]);
  }, [setTransitionState]);

  useEffect(() => {
    if (transitionState === TRANSITION_IN_ENTER) {
      timeline.current.play(0);
    }
  }, [transitionState]);

  return ReactDOM.createPortal((
    <div ref={baseRef} className={styles.Curtain}>
      <Logo className={styles.Logo} ref={loadingRef} />
    </div>
  ), document.getElementById('CurtainPortal'));
};

export default Curtain;
