import React from 'react';
import clsx from 'clsx';
import styles from './SeeMore.module.scss';

const SeeMore = ({ className, ...rest }) => {
  return (
    <button
      {...rest}
      className={clsx(styles.SeeMore, className)}
      tabIndex="0"
    >SEE MORE</button>
  );
};

export default SeeMore;
