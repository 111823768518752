import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Curtain from '../components/Curtain';
import { useLoco } from '../state/LocoContext';

export const TRANSITION_IN_ENTER = 'TRANSITION_IN_ENTER';
export const TRANSITION_IN_DONE = 'TRANSITION_IN_DONE';
export const TRANSITION_OUT_ENTER = 'TRANSITION_OUT_ENTER';
export const TRANSITION_OUT_DONE = 'TRANSITION_OUT_DONE';

const TransitionContext = React.createContext({
  navigate: () => {},
});

export function TransitionContextProvider({ children }) {
  const [transitionState, setTransitionState] = useState(null);
  const [queuedPath, setQueuedPath] = useState(null);
  const history = useHistory();
  const { pathname } = useLocation();
  const { scrollTop, scrollTo } = useLoco();

  const navigate = useCallback((path, showTransition = true) => {
    if (path === pathname) return;
    history.replace({
      ...history.location,
      state: {
        ...history.location.state,
        scrollTop: scrollTop(),
      }
    })
    if (!showTransition) {
      return history.push(path);
    }

    setQueuedPath(path);
    setTransitionState(TRANSITION_IN_ENTER);
  }, [history, pathname, scrollTop]);

  useEffect(() => {
    const unregisterListener = history.listen(({state}, action) => {
      if (action === 'POP') {
        if (state && state.scrollTop) {
          scrollTo(0,state.scrollTop,0);
        } else {
          scrollTo(0,0,0);
        }
      } else if (action === 'PUSH') {
        scrollTo(0,0,0);
      }
    });

    return () => {
      unregisterListener();
    }
  }, [history, scrollTo]);

  useEffect(() => {
    if (transitionState === TRANSITION_IN_DONE && queuedPath !== null) {
      setQueuedPath(null);
      history.push({
        pathname: queuedPath,
        state: {
          from: pathname,
        }
      });
    }
  }, [history, pathname, queuedPath, transitionState]);

  return (
    <TransitionContext.Provider value={{
      navigate,
      transitionState,
      setTransitionState,
    }}>
      {children}
      <Curtain />
    </TransitionContext.Provider>
  )
}

export function useTransition() {
  return useContext(TransitionContext);
}

export default TransitionContext;
