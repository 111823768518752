import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Accordion, AccordionSummary, AccordionDetails } from '../../components';
import useMediaQuery from '../../hooks/useMediaQuery';
import styles from './DetailSheet.module.scss';

const TabPanel = ({ value, index, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
    >
      {children}
    </div>
  );
};

const DetailSheet = ({ tabs, ...props }) => {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const { isTablet } = useMediaQuery();
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleMobileChange = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  return (
    <section {...props} className={`${styles.Container} color-white`}>
      <h4>Abstract</h4>
      <div>
        {isTablet ? (
          <>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="detail sheet tabs"
            >
              {tabs.map(tab => (
                <Tab
                  classes={{
                    textColorPrimary: styles.Tab,
                  }}
                  key={tab.title}
                  disableRipple
                  label={tab.title}
                />
              ))}
            </Tabs>
            {tabs.map((tab, idx) => (
              <TabPanel key={tab.title} value={value} index={idx}>
                <div className={styles.TabContent}>
                  <h3>{tab.title}</h3>
                  <ul>
                    {tab.details && tab.details.map(({ detail, order }) => (
                      <li key={`detail_${order}`}>{detail}</li>
                    ))}
                  </ul>
                </div>
              </TabPanel>
            ))}
          </>
        ) : (
          <>
            {tabs.map((tab) => (
              <Accordion
                square
                key={tab.title}
                expanded={expanded === tab.title}
                onChange={handleMobileChange(tab.title)}
              >
                <AccordionSummary
                  expandIcon={<span className="material-icons">add</span>}
                >
                  {tab.title}
                </AccordionSummary>
                {tab.details && (
                  <AccordionDetails>
                    <ul>
                      {tab.details.map(({ detail, order }) => (
                        <li key={`detail_${order}`}>{detail}</li>
                      ))}
                    </ul>
                  </AccordionDetails>
                )}
              </Accordion>
            ))}
          </>
        )}
      </div>
    </section>
  );
};

export default React.memo(DetailSheet);
