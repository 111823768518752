import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import gsap from 'gsap';
import { Transition } from 'react-transition-group';

import styles from './Slideshow.module.scss';

const Thumbnails = ({images,
  showThumbs,
  activeIdx,
  onSelectThumb = () => {},
  onClose = () => {},
}) => {
  const rootEl = useRef(null);
  const tl = useRef(null);
  
  useEffect(() => {
    if (rootEl.current) {
      const thumbImages = rootEl.current.querySelectorAll(`.${styles.ThumbnailsWrapper} > div`);
      
      tl.current = gsap.timeline({
        paused: true,
      })
        .fromTo(rootEl.current, {
          autoAlpha: 0,
        },{
          duration: 0.25,
          autoAlpha: 1,
          ease: 'Power3.out',
        })
        .fromTo(thumbImages, {
          opacity: 0,
          y: '+=15',
        }, {
          duration: 0.3,
          stagger: 0.02,
          opacity: 1,
          y: 0,
          clearProps: 'opacity',
          ease: 'back.out(1.7)',
        }, '-=0.15');
    }

    return () => {
      if (tl.current) tl.current.kill();
    }

  }, []);

  useEffect(() => {
    const listener = (e) => {
      if (!rootEl.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    }
  }, [onClose]);

  return (
    <Transition
      in={showThumbs}
      addEndListener={(node, done) => {
        showThumbs
          ? tl.current.play(0).then(done)
          : tl.current.reverse(0).then(done);
      }}
    >
      <div className={styles.Thumbnails} ref={rootEl}>
        <div className={styles.ThumbnailsWrapper}>
          {images && images.map((thumb, idx) => (
            <div
              className={clsx({ active: idx === activeIdx })}
              key={thumb.ID}
              onClick={() => onSelectThumb(idx)}
            >
              <img src={thumb.sizes.thumbnail} alt={thumb.alt} />
            </div>
          ))}
        </div>
      </div>
    </Transition>
  );
};

export default Thumbnails;
